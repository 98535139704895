@import url('https://fonts.googleapis.com/css?family=Noto+Sans:100,300,500,400,600,700,800');
@import './vars';

body {
  margin: 0;
  font-size: 14px;
  text-rendering: optimizeLegibility; 
  font-family: 'Noto Sans', sans-serif;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

#searchForm > .ant-row > .ant-row.ant-form-item {
  width: 100%;
  text-align: right;
  padding-right: 12px;
  .ant-col {
    flex: 1;
    max-width: 100%;
    width: 100%;
    .ant-btn:first-child {
      margin-right: 10px;
    }
  }
}

.app-loading-wrapper.ant-spin-spinning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.layout-page-content-style{
  min-height: 100%;
  padding: 24px 15px;
  width: 100%;
  padding-bottom: 80px;
}

.space-between-flex{
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.error-form-item .ant-form-item-explain {
  color: red;
}

.RichTextEditor__editor___1QqIU {
  height: 200px!important;
}

.RichTextEditor__root___2QXK-{
  font-family: 'Poppins', sans-serif!important;
}

.rich-text-editor-normal{
  border: 1px solid #d9d9d9!important;
  overflow: hidden !important;
  padding-bottom: 10px !important;
}

.rich-text-editor-error{
  border: 1px solid red!important;
}

.ant-btn-primary {
  background-color: $primary !important;
}

.whiteBox {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.layoutPadding {
  position: relative;
  padding: 50px 40px;
}

@media only screen and (max-width: 600px) {
  .layoutPadding {
    padding: 50px 20px;
  }
}

.shadow {
  box-shadow: 0px 0px 20px 3px rgba(150, 190, 238, 0.15);
}

h3:where(.css-dev-only-do-not-override-vi14u1).ant-typography,
div:where(.css-dev-only-do-not-override-vi14u1).ant-typography-h3,
div:where(.css-dev-only-do-not-override-vi14u1).ant-typography-h3>textarea,
:where(.css-dev-only-do-not-override-vi14u1).ant-typography h3 {
  color: $primary; /* Replace with your desired color */
}

.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}