.ant-list-item {
  padding: 12px 12px !important;
}

.ant-table-cell .ant-btn.ant-btn-link {
  padding: 0 4px !important;
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}

//AntD <Select /> Overide Styles Start

.ant-select-selector{
  background-color: white !important;
}

//AntD <Select /> Overide Styles End

//AntD <Table /> Overide Styles Start

.ant-table-cell::before{
  display: none;
}

.ant-table-wrapper .ant-table-thead >tr>th {
  background-color: unset;
}

//AntD <Table /> Overide Styles End

//Ant Tabs Style Override Local START

.ant-tabs-navigational .ant-tabs-nav{
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px !important;
  padding-bottom: 10px !important;

  @media screen and (max-width: 992px) {
    padding: 0px 32px;
    margin-top: -5px !important;
  }
}

.ant-tabs-navigational .ant-tabs-nav-list{
  width: 100% ;
  align-items: center!important;
  padding: 0px 2px;
  max-width: 800px;
}

.ant-tabs-navigational .ant-tabs-nav-wrap{
  display: flex ;
  flex-direction: column;
  justify-content: center ;
  align-items: center !important;
  height: 56px;
  width: 100%;
  background-color: white;
  max-width: 800px;
  border-radius: 14px;
  border: 1px solid black;

  @media screen and (max-width: 992px) {
    font-size: 14px !important;
    height: 48px;
  }
}

.ant-tabs-navigational .ant-tabs-tab{
  flex: 1 ;
  justify-content: center;
  text-transform: uppercase;
  height: 50px;
  border-radius: 12px !important;
  background-color: #fff !important;

  @media screen and (max-width: 992px) {
    padding: 0px 32px;
    font-size: 14px !important;
    height: 44px;
  }
}

.ant-tabs-navigational .ant-tabs-tab-active{
  background-color: #000 !important;
  color: white !important;
  border: 0 !important;
}

.ant-tabs-navigational .ant-tabs-tab-active div{
  color: white !important;
}

//Ant Tabs Style Override Local END

.card-default-controller .ant-card-body {
  padding: 0px;
}



//Ant Tabs Style Override Local START

.ant-tabs-portfolio .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-portfolio .ant-tabs-nav-list {
  width: 100%;
  align-items: center !important;
  padding: 0px 2px;
}

.ant-tabs-portfolio .ant-tabs-nav-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 14px;
  border: 1px solid black;

  @media screen and (max-width: 992px) {
      font-size: 10px !important;
      height: 48px;
  }
}

.ant-tabs-portfolio .ant-tabs-tab {
  flex: 1;
  justify-content: center;
  text-transform: uppercase;
  height: 50px;
  border-radius: 12px !important;
  background-color: #fff !important;
}

.ant-tabs-portfolio .ant-tabs-tab-active {
  background-color: #000 !important;
  color: white !important;
  border: 0 !important;
}

.ant-tabs-portfolio .ant-tabs-tab-active div {
  color: white !important;
}

//Ant Tabs Style Override Local END

.ant-tabs-reset .ant-tabs-nav-list {
  width: unset;
  align-items: unset;
}

.ant-tabs-reset .ant-tabs-nav-wrap {
  display: unset !important;
  height: unset !important;
  border: unset !important;
  border-radius: unset !important;

}

.ant-tabs-reset .ant-tabs-tab {
  flex: unset !important;
  text-transform: unset !important;
  height: unset !important;
  background-color: unset !important;
  color: unset !important;
  font-size: 15px !important;
}

.ant-tabs-reset .ant-tabs-tab-active {
  border: unset !important;
}

.ant-tabs-reset .ant-tabs-tab-active div {
  color: unset !important;
}

.custom-tab-profile {

  & .ant-tabs-nav-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    width: 100%;
  }

  & .ant-tabs-nav-list{
    width: 100%;
  }

  & .ant-tabs-tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    font-size: 16px;
    font-weight: 500;
  }
}

.ant-upload-drag-container{
  padding: 32px;
}

.menu-controller{

  .ant-menu-item{
    padding-left:  20px !important;
    padding-right:  20px !important;
  }
  
  .ant-menu-item-selected{
    background-color: white !important;
  }

  .ant-menu-item:hover{
    color: unset !important;
    background-color: rgba(255, 255, 255, 0.441) !important;
  }

  .ant-menu-item-selected:hover{
    background-color: white !important;
    color: black !important;
  }
}

.ant-steps-icon{

  svg {
    path {
      fill: white;
    }
  }
}

.ant-btn{
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  appearance: textfield;
    -moz-appearance:textfield; /* Firefox */
}

.ant-select-item-option-selected {
  color: white !important;
  font-weight: 400 !important;
}

.property-table .ant-table-thead{
  background-color: #EAEAEA !important;
}

.ant-upload-list-item-progress{
  display: none;
}

.ant-upload-list-item-uploading{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ant-upload-list-item-thumbnail{
    width: fit-content !important;
    height: fit-content !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }
}

.ant-upload-list-item-thumbnail {
  padding-top: 10px;
}

.login-form .ant-form-item-required{
  color: #868686 !important;
  font-size: 13px !important;
}

.ant-form-item-required::before{
 display: none !important;
}

.login-form .ant-form-item-required::after{ 
  display: none !important;
}

.ant-form-item-required::after{
    display: inline-block !important;
    margin-inline-end: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun,sans-serif !important;
    line-height: 1 !important;
    content: " *" !important;
 }

 .ant-form-item-label > label{
  font-size: 13px !important;
 }

.property-list-card .ant-card-body {
  padding: 0 !important;
}


.custom-stepper .ant-steps-item-content{
  font-weight: 600;
}

.custom-stepper .ant-steps-icon {
  font-weight: 600;
}

.custom-stepper .ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #8B8B8B !important;
    border: none !important;
  }

  .ant-steps-item-title{
    color: #8B8B8B !important;
  }
}

.custom-stepper .ant-steps-item-icon {
  background-color: #cfcfcf !important;

  span{
    color: white !important; 
  }
}

.custom-stepper .ant-steps-item .ant-steps-item-title::after{
  background-color: #cfcfcf !important;
}

.custom-stepper .ant-steps-item-active .ant-steps-item-title::after{
  background-color: #000 !important;
}

.custom-stepper .ant-steps-item-active{
  .ant-steps-item-icon {
    background-color: #000 !important;
  }
}

// Custom form CSS START

.custom-form .ant-input, .ant-select-selector, .ant-input-affix-wrapper, .ant-picker, .ant-input-number-affix-wrapper{
  border: 1px solid #acacac !important;
}

.custom-form .ant-input-number-handler-wrap {
  display: none;
}

.custom-form .ant-select-selector .ant-select-selection-placeholder, .ant-input::placeholder{
  color: #a4a4a4;
}

.custom-form .ant-input-affix-wrapper{
  .ant-input {
    border: none !important;
  }
}

.custom-form .ant-divider{
  background-color: #d7d7d7 !important;
}

.custom-form .ant-input-group-addon{
  border: 1px solid #acacac !important;
  border-left: none !important;

  .ant-select-selector {
    height: 40px !important;
  }
}

.add-on-reset .ant-input-group-addon {
  border: 1px solid #acacac !important;
  border-right: none !important;
}

.custom-form .ant-input,.ant-input-affix-wrapper, .ant-input-number {
  height: 50px !important;
  display: flex;
  align-items: center !important;
}

.custom-form .ant-input-affix-wrapper {
  .ant-input {
    height: 40px !important;
  }
}

.slick-dots {
  bottom: 20px !important;
  button {
    box-shadow: 0px 2px 6px #000 !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 32px !important;
  }

  .slick-active {
    width: 100%;
    button {
      box-shadow: 0px 2px 6px #0000003f !important;
      border-radius: 32px !important;
      width: 24px !important;
    }
  }
}

.custom-form .ant-picker {
  height: 50px !important;
}


.custom-form .ant-select-selector{
  height: 50px !important;
  display: flex;
  align-items: center !important;
}

.custom-form .custom-multi-drop .ant-select-selector {
  min-height: 50px !important;
  height: auto !important;
}

// Custom form CSS END


.property-filter .ant-select-selection-placeholder {
  color: #000;
}

.ant-input-group-addon .ant-select-selector {
  border: none !important;
}

.property-filter .ant-input-affix-wrapper{
  height: 50px !important;
}

.property-filter .ant-select-selector{
  height: 50px !important;
  display: flex;
  align-items: center !important;
}

.avatar-uploader .ant-upload-select{
  border: 1px dashed #B1B1B1 !important;
}

.detail-icon svg {
  font-size: 28px !important;
}

.ant-notification-notice-error{
  background-color: #FCE5E4 !important;
}

.ant-notification-notice-info{
  background-color: #E3ECFE !important;
}

.ant-notification-notice-warning{
  background-color: #FDF3E3 !important;
}

.ant-notification-notice-success{
  background-color: #EAF6E2 !important;
}

.login-input .ant-input{
  border-color: #acacac !important;
  height: 50px;
}

// .input-reset  .ant-input-affix-wrapper{
//   min-height: 90px !important;
// }

// .input-reset .ant-input-affix-wrapper .ant-input{
//   min-height: 80px !important;
// }

// .input-reset .ant-input-data-count {
//   bottom: -32px;
// }

.custom-form{
  textarea {
    min-height: 100px !important;
  }
}

.ant-upload-list-item-container{
  padding: 0 !important;

  img {
    object-fit: cover !important;
  }
  
  .ant-upload-list-item{
    overflow: hidden !important;
    padding: 0 !important;
  }
  a{
    padding: 0 !important;
  }
}

.ant-upload-list-item::before {
  width: 100% !important;
  height: 100% !important;
}

.info-card .ant-card-body{
  margin:  0 !important;

  img {
    margin:  0 !important;
  }
}

.graph-card .ant-card-body {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.form-input .ant-input-number-input{
  height: 50px !important;
}

.ant-input-number-handler-wrap {
  opacity: 0 !important;
}

.form-input .ant-input-number {
  border: 1px solid #acacac !important
}

.form-input .ant-input-number-group-addon {
  border: 1px solid #acacac !important;
  border-left: none !important;
}

.form-input .ant-select-selector {
  border: none !important;
}

.ant-progress-text{
  transform: rotateZ(90deg)!important;
}

.custom-menu .ant-menu{
  padding: 20px 16px !important;
}

.filter-card {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.filter-card-footer {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top: 1px solid $grey;

  .ant-card-body {
    padding-bottom: 8px;
  }
}

.custom-list-item {
  display: flex;

  @media screen and (max-width: $ipadWidthBreakpoint) {
    flex-direction: column;
    align-items: flex-start !important;
    border-bottom: 1px solid $grey;
    padding-bottom: 32px !important;

    .ant-list-item-meta {
      width: 100%;
      flex: 1;
      margin-bottom: 20px;
    }
  }

}