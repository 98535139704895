$grey: #E2E2E2;
$secondaryGrey: #f8f8f8;

$grey:#D2D4E3;
$sysGrey: #797EAA;
$primary: #202872;
$maxWidth: 1600px;
$selectedGreen: #202872;
$buttonGreen: #202872;
$secondaryGreen: #202872;


// breakpoint variables
$lgWidthBreakpoint: 1200px;
$ipadWidthBreakpoint: 992px;
$phoneWidthBreakpoint: 576px;
$smWidthBreakpoint: 768px;


@mixin max-width-controller() {
  max-width: $maxWidth;
  margin: 0 auto;
}

:export {
  primary: $primary;
  grey: $grey
}