@import '../../styles/vars';
.layout{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  flex-direction: column;
  gap: 60px;
  height: 100vh;
}

.card-control{
  background-color: #fff;
  color: black !important;
  width: 100%;
  margin: 0px 20px;
  max-width: 780px;
}

.login-button{
  margin-top: 20px;
  height: 50px !important;
}