@import '../../styles/vars';

.layout-page {
  display: flex;
  &-header {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    position: sticky;
    top: 0;
    z-index: 300;

    @media screen and (min-width: $ipadWidthBreakpoint) {
      height: 72px;
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &-main {
      padding: 0 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      height: 100%;
      background-color: $primary;
      width: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img{
        width: 56px;
        height: 56px;
      }
    }
  }
  &-sider {
    box-sizing: border-box;
    background-color: $primary !important;
    color: white;
    height: 100vh;
    font-weight: 600;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;;
  }
  &-footer {
    text-align: center;
    padding: 14px 20px;
    font-size: 12px;
  }

  .user-action {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  .user-avator {
    width: 45px;
    height: 45px;
  }
}

.route-area{
  max-width: 1500px;
  width: 100%;
}

body[theme-mode='dark'] {
  .layout-page-header {
    box-shadow: none;
  }
}

.layout-page-sider-menu {
  border-right: none !important;
}
.ant-menu-inline-collapsed {
  width: 79px !important;
}

.notice-description {
  font-size: 12px;
  &-datetime {
    margin-top: 4px;
    line-height: 1.5;
  }
}

.notice-title {
  display: flex;
  justify-content: space-between;
}

.tagsView-extra {
  height: 100%;
  width: 50px;
  cursor: pointer;
  display: block;
  line-height: 40px;
  text-align: center;
}

.theme-color-content {
  display: flex;
  .theme-color-block {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    border-radius: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.image-logo{
  object-fit: cover;
}

.logo-controller{
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 20px;

  justify-content: center;
  border-bottom: 1px solid white;

  img{
    width: 180px;
    height: 50px;
  }
}

.menu-cell{
  & + & {
    margin-top: 20px;
  }
}

.sticky-position{
  position: sticky;
  top: 0;
  height: 100%;
}

.user-action{
  width: 100%;
  
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-avator{
  background-color: green;
  border-radius: 50%;
  // margin-top: 20px;
}

.dropdown{
  width: 100%;
}
.link{
  cursor: pointer;
}

.button-control{
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.modal{
  p {
    text-align: center;
    margin: 20px 0px;
  }
}

.content-sider{
  padding: 0px 20px;
}